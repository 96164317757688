import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { ResizableBox } from "react-resizable";

import { Input } from "../../../Input";
import { placeholder } from "../../consts";

import "react-resizable/css/styles.css";

const minImageWidth = 40; // pixels;

// restriction imposed by previewer CSS
const maxImageWidth = 720; // pixels

// valid size restriction formats are
//
//   size=[<width>,<height>] - aspect ratio > 1 -> limited by maximum width
//                           - aspect ratio < 1 -> limited by maximum height
//   size=[<width>]          - maximum height = aspect ratio * width
const sizeRegEx = /size=\[\d+(,\d+)?\]/i;

export default class ImageWidget extends Component {
  static propTypes = {
    alt: PropTypes.string,
    attr: PropTypes.shape({
      size: PropTypes.string,
    }),
    src: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  };

  static defaultProps = {
    alt: placeholder.text,
  };

  constructor(props) {
    super(props);

    const {
      attr: { size: origSize },
      src,
    } = this.props;
    this.state = {
      aspectRatio: 1,
      minConstraints: [0, 0],
      maxConstraints: [0, 0],
      origSize, // remember initial size restriction
      size: {
        width: 0,
        height: 0,
      },
      src, // <img src=...> should only be changed by Asset Explorer
      value: 0,
      valueIsInvalid: false,
    };
  }

  onClick = () => {
    const { attr, onAssetsOpen } = this.props;
    const { origSize: size } = this.state;

    onAssetsOpen(
      {
        ...attr,
        size, // always open Asset Explorer with initial size restriction
      },
      (asset) => {
        const { attr, assetId, url, _meta: { mimeType: type, locale } } = asset;
        const { attr: { mimeType } } = this.props;

        this.setState({ src: url });
        this.onUpdate(url, {
          ...attr,
          assetId,
          type,
          mimeType,
          locale,
        });
      }
    );
  };

  onInputChange = (event) => {
    const {
      aspectRatio,
      minConstraints: [minWidth],
      maxConstraints: [maxWidth],
    } = this.state;
    const value = +(+event.target.value).toFixed(); // convert to integer
    const valueIsInvalid = value < minWidth || value > maxWidth;

    this.setState({
      value,
      valueIsInvalid,
      ...(valueIsInvalid
        ? undefined // don't touch size for invalid value
        : {
          size: {
            width: value,
            height: value * aspectRatio,
          },
        }),
    }, () => {
      // handle manual update like end of resize
      if (!valueIsInvalid) this.onResizeStop();
    });
  };

  onLoad = () => {
    let { naturalHeight: height, naturalWidth: width } = this._image;
    const aspectRatio = height / width;
    const minImageHeight = minImageWidth * aspectRatio;

    // limit maximum image width
    if (width > maxImageWidth) {
      width = maxImageWidth;
      height = width * aspectRatio;
    }

    // initialize constraints from loaded image
    this.setState({
      aspectRatio,
      minConstraints: [minImageWidth, minImageHeight],
      maxConstraints: [width, height],
      size: {
        width: width.toString(),
        height: height.toString(),
      },
      value: width,
      valueIsInvalid: false,
    });
  };

  onResize = (_, { size }) =>
    this.setState({
      size: {
        width: size.width.toString(),
        height: size.height.toString(),
      },
      value: +size.width.toFixed(), // convert to integer
      valueIsInvalid: false,
    });

  onResizeStop = () => {
    const { attr } = this.props;
    const {
      size: { height, width },
      src,
    } = this.state;
    // asset API only accepts integers
    const parsedWidth = parseFloat(width);
    const parsedHeight = parseFloat(height);
    const size = `[${parsedWidth.toFixed()},${parsedHeight.toFixed()}]`;

    this.onUpdate(
      src.match(sizeRegEx)
        ? src.replace(sizeRegEx, `size=${size}`)
        : `${src.trimEnd()}?size=${size}`,
      {
        ...attr,
        size,
      }
    );
  };

  onUpdate(src, attr) {
    const { alt, onHandleContent, uuid } = this.props;

    Object.assign(attr, { _type: "ao1" });
    onHandleContent({
      alt,
      attr,
      src,
      uuid,
    });
  }

  render() {
    const { alt, attr } = this.props;
    const { minConstraints, maxConstraints, size, src, value, valueIsInvalid } =
      this.state;

    return (
      <div className="rule-builder__img">
        <ResizableBox
          lockAspectRatio
          minConstraints={minConstraints}
          maxConstraints={maxConstraints}
          onResize={this.onResize}
          onResizeStop={this.onResizeStop}
          resizeHandles={["n", "nw", "ne", "w", "e", "s", "sw", "se"]}
          width={parseInt(size.width)}
          height={parseInt(size.height)}
        >
          <Fragment>
            <img
              alt={alt}
              onClick={this.onClick}
              onLoad={this.onLoad}
              ref={(ref) => (this._image = ref)}
              src={src}
              width={size.width}
              height={size.height}
            />
            <div className="rule-builder__img_inputs">
              <div className="label">Width</div>
              <Input
                className={`input input_filter ${
                  valueIsInvalid ? "input_warning" : ""
                }`}
                onChange={this.onInputChange}
                type="number"
                value={value}
              />
            </div>
          </Fragment>
        </ResizableBox>
      </div>
    );
  }
}