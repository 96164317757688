import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {genericActions} from '@dized/ui/dist/redux/OrmHelper';
import {get, patchNoHeader, postFile} from '@dized/ui/dist/redux/RequestHelper';
import {apiPath as folderApiPath} from './FolderActions';

const apiPath = '/api/v1/assets/asset';
const modelName = 'ASSET';

export default initActions('AssetAction', {
  ...genericActions({modelName}, apiPath),
  FETCH_ASSETS: (params) => get(apiPath, params),
  UPDATE_ASSET: (id, attrs) => patchNoHeader(`${apiPath}/${id}`, attrs),
  UPLOAD_ASSET: (file, uuid, locale) => postFile(`${folderApiPath}/${uuid}/${locale}/upload`, file),
});